import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { get, flatten, map } from '../../lib/nodash';
import { Box, Heading, Text } from 'grommet';

import ButtonsProductCartOptions from '../ButtonsProductCartOptions';
import {
  getProductPath,
  findSampleVariant,
  findInteriorStandardShopifyProduct,
} from '../../lib/product';
import useMobile from '../useMobile';
import useAdjustedHeadingSize from '../useAdjustedHeadingSize';

const DynamicPaintCardPartnership = ({
  blok: {
    paintCollection,
    headingLevel = 3,
    sizeAsHeadingLevel,
    useColorBorder,
    hideBorder = false,
    pad = 'large',
    buttonFill,
  },
  ...rest
}) => {
  const isMobile = useMobile();
  const collection = get('item', paintCollection) || paintCollection;
  const variants = flatten(map('variants', collection.products));
  const sampleVariant = useMemo(() => findSampleVariant(variants), [variants]);
  const interiorStandardProduct = findInteriorStandardShopifyProduct(
    collection.products
  );
  const detailsPath = useMemo(() => getProductPath(interiorStandardProduct));
  const headingStyle = useAdjustedHeadingSize(
    sizeAsHeadingLevel || headingLevel
  );

  return (
    <Box
      pad={pad}
      align="center"
      justify="center"
      gap="medium"
      border={
        hideBorder
          ? undefined
          : {
              side: 'all',
              color: useColorBorder ? collection.hex_value : 'brand',
              size: 'small',
            }
      }
      style={
        useColorBorder
          ? {
              boxShadow: `0px 0 4px ${collection.hex_value}, inset 0 0 4px ${collection.hex_value}`,
            }
          : rest.style || {}
      }
      {...rest}
    >
      <Heading textAlign="center" level={headingLevel} style={headingStyle}>
        {collection.title}
      </Heading>
      <Text style={{ letterSpacing: '1px' }} weight={500} textAlign="center">
        {collection.short_description}
      </Text>
      <Text style={{ letterSpacing: '1px' }} textAlign="center">
        {collection.description}
      </Text>
      <ButtonsProductCartOptions
        buttonFlex={buttonFill}
        fill={buttonFill ? 'horizontal' : undefined}
        product={collection}
        detailsPath={detailsPath}
        useIcon={true}
        variant={sampleVariant}
        gap={isMobile ? 'medium' : 'none'}
        defaultBorders={isMobile}
        border={
          isMobile
            ? []
            : [
                { side: 'vertical', size: 'small' },
                { side: 'bottom', size: 'small' },
              ]
        }
      />
    </Box>
  );
};

DynamicPaintCardPartnership.propTypes = {
  blok: PropTypes.shape({
    paintCollection: PropTypes.object,
    useColorBorder: PropTypes.bool,
    headingLevel: PropTypes.oneOf([
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      1,
      2,
      3,
      4,
      5,
      6,
    ]),
    sizeAsHeadingLevel: PropTypes.oneOf([
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      1,
      2,
      3,
      4,
      5,
      6,
    ]),
    pad: PropTypes.string,
    hideBorder: PropTypes.bool,
    buttonFill: PropTypes.bool,
  }),
};

export default memo(DynamicPaintCardPartnership);
